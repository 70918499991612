export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182')
];

export const server_loads = [0,2,3,6];

export const dictionary = {
		"/": [~7],
		"/best-sellers": [~8],
		"/cart": [~9,[2]],
		"/cart/checkout": [~10,[2,3]],
		"/cart/checkout/complete-purchase": [~11],
		"/cart/checkout/review": [~12,[2,3]],
		"/cart/reorder": [~13,[2]],
		"/colors": [~14],
		"/colors/bundles": [~16],
		"/colors/collections": [17],
		"/colors/collections/[slug]": [~18],
		"/colors/[...slug]": [~15],
		"/customers/account": [~20],
		"/customers/signout": [~21],
		"/customer/account/[slug]": [~19],
		"/enhancements": [22],
		"/enhancements/hard-gels": [~23],
		"/enhancements/polygel": [~24],
		"/enhancements/prohesion": [~25],
		"/enhancements/xpress-dip-n-brush": [~26],
		"/faq": [27],
		"/follow-us": [28],
		"/forget-password": [29],
		"/gelish": [~30],
		"/gelish/cat-eye": [~31],
		"/gelish/flash-gels": [~32],
		"/learn-more": [33],
		"/morgan-taylor": [~34],
		"/morgan-taylor/naturals": [~35],
		"/nail-arts": [~36],
		"/nail-arts/cream-gel": [~37],
		"/nail-care": [~38],
		"/nail-care/bare-luxury": [~39],
		"/nail-lamps-tools": [40],
		"/nail-lamps-tools/brushes-and-tools": [~41],
		"/nail-lamps-tools/e-files": [~42],
		"/nail-lamps-tools/led-lamps": [~43],
		"/nail-lamps-tools/vortex": [~44],
		"/nail-style-blog": [~45,[4]],
		"/nail-style-blog/In-full-bloom": [46,[4]],
		"/nail-style-blog/backstage-beauty": [47,[4]],
		"/nail-style-blog/beat-the-heat": [48,[4]],
		"/nail-style-blog/behind-the-scenes": [49,[4]],
		"/nail-style-blog/benefits-of-gelish-vortex": [50,[4]],
		"/nail-style-blog/berry-bliss": [51,[4]],
		"/nail-style-blog/bit-by-bit": [52,[4]],
		"/nail-style-blog/black-friday-buys": [53,[4]],
		"/nail-style-blog/blooming-201": [54,[4]],
		"/nail-style-blog/blooming-beginnings": [55,[4]],
		"/nail-style-blog/bridal-nail-prep": [56,[4]],
		"/nail-style-blog/career-boost": [57,[4]],
		"/nail-style-blog/chart-topping": [58,[4]],
		"/nail-style-blog/cheers": [59,[4]],
		"/nail-style-blog/chill-proof-your-skin": [60,[4]],
		"/nail-style-blog/client-consults": [61,[4]],
		"/nail-style-blog/color-me-stylish": [62,[4]],
		"/nail-style-blog/color-quiz": [63,[4]],
		"/nail-style-blog/competitive-edge": [64,[4]],
		"/nail-style-blog/crystal-celebration": [65,[4]],
		"/nail-style-blog/dip-tricks": [66,[4]],
		"/nail-style-blog/exercises-to-do": [67,[4]],
		"/nail-style-blog/extension-expression": [68,[4]],
		"/nail-style-blog/eye-on-ingredients-nourish": [69,[4]],
		"/nail-style-blog/fall-fashion-forecast": [70,[4]],
		"/nail-style-blog/fall-fashion-preview": [71,[4]],
		"/nail-style-blog/fall-layers": [72,[4]],
		"/nail-style-blog/fashion-week": [~73,[4,5]],
		"/nail-style-blog/fashion-week/[season]": [~74,[4,5]],
		"/nail-style-blog/fashion-week/[season]/[designer]": [~75,[4,5]],
		"/nail-style-blog/festive-recipes-holiday-season": [76,[4]],
		"/nail-style-blog/fitness-made-simple": [77,[4]],
		"/nail-style-blog/five-must-have-shoes-for-summer": [78,[4]],
		"/nail-style-blog/flash-back": [79,[4]],
		"/nail-style-blog/flex-your-power": [80,[4]],
		"/nail-style-blog/fun-in-the-sun": [81,[4]],
		"/nail-style-blog/get-glowing-summer-skin": [82,[4]],
		"/nail-style-blog/getaway-goals": [83,[4]],
		"/nail-style-blog/give-some-lip": [84,[4]],
		"/nail-style-blog/giving-thanks": [85,[4]],
		"/nail-style-blog/glittery-and-bright": [86,[4]],
		"/nail-style-blog/glitz-and-glam": [87,[4]],
		"/nail-style-blog/globetrotting": [88,[4]],
		"/nail-style-blog/go-it-alone": [89,[4]],
		"/nail-style-blog/gratitude-unboxed": [90,[4]],
		"/nail-style-blog/h20-glow": [91,[4]],
		"/nail-style-blog/happy-anniversary": [92,[4]],
		"/nail-style-blog/head-to-toe": [93,[4]],
		"/nail-style-blog/healthy-eating-during-the-holidays": [94,[4]],
		"/nail-style-blog/holiday-hues": [95,[4]],
		"/nail-style-blog/how-to-plan": [96,[4]],
		"/nail-style-blog/innovative-inventions": [97,[4]],
		"/nail-style-blog/light-it-up": [98,[4]],
		"/nail-style-blog/magnetic-appeal": [99,[4]],
		"/nail-style-blog/making-waves": [100,[4]],
		"/nail-style-blog/mix-and-match": [101,[4]],
		"/nail-style-blog/moments-with-mom": [102,[4]],
		"/nail-style-blog/nail-your-gift-list": [103,[4]],
		"/nail-style-blog/naked-nails": [104,[4]],
		"/nail-style-blog/need-for-speed": [105,[4]],
		"/nail-style-blog/new-years-resolution": [106,[4]],
		"/nail-style-blog/note-from-danny": [107,[4]],
		"/nail-style-blog/ones-to-swatch": [108,[4]],
		"/nail-style-blog/over-the-top": [109,[4]],
		"/nail-style-blog/pack-like-a-pro": [110,[4]],
		"/nail-style-blog/perk-up-your-pedicures": [111,[4]],
		"/nail-style-blog/platinum-vinyl": [112,[4]],
		"/nail-style-blog/poolside-reads": [113,[4]],
		"/nail-style-blog/posh-prom": [114,[4]],
		"/nail-style-blog/prep-for-pedi-season": [115,[4]],
		"/nail-style-blog/reviving-retail": [116,[4]],
		"/nail-style-blog/satisfying-snacks": [117,[4]],
		"/nail-style-blog/say-nailfie": [118,[4]],
		"/nail-style-blog/scratch-magazine": [119,[4]],
		"/nail-style-blog/seasonal-skincare-switch-up": [120,[4]],
		"/nail-style-blog/secrets-of-my-success": [121,[4]],
		"/nail-style-blog/secrets-of-trade-show-success": [122,[4]],
		"/nail-style-blog/self-care-in-the-digital-age": [123,[4]],
		"/nail-style-blog/setting-the-gold-standard": [124,[4]],
		"/nail-style-blog/simple-pleasures": [125,[4]],
		"/nail-style-blog/simple-stretches-for-back-health": [126,[4]],
		"/nail-style-blog/sip-and-stir": [127,[4]],
		"/nail-style-blog/six-spring-fashion-musts": [128,[4]],
		"/nail-style-blog/six-ways-to-exercise-this-fall": [129,[4]],
		"/nail-style-blog/sparkle-and-shine": [130,[4]],
		"/nail-style-blog/spooky-chic": [131,[4]],
		"/nail-style-blog/spotlight-on-2": [133,[4]],
		"/nail-style-blog/spotlight-on-3": [134,[4]],
		"/nail-style-blog/spotlight-on-4": [135,[4]],
		"/nail-style-blog/spotlight-on-5": [136,[4]],
		"/nail-style-blog/spotlight-on-6": [137,[4]],
		"/nail-style-blog/spotlight-on": [132,[4]],
		"/nail-style-blog/spring-fling": [138,[4]],
		"/nail-style-blog/stick-it-to-me": [139,[4]],
		"/nail-style-blog/stuck-on-you": [140,[4]],
		"/nail-style-blog/sugar-spice-and-everything-iced": [141,[4]],
		"/nail-style-blog/summer-sips": [142,[4]],
		"/nail-style-blog/sun-kissed-beauty": [143,[4]],
		"/nail-style-blog/sustainable-style": [144,[4]],
		"/nail-style-blog/sweater-weather": [145,[4]],
		"/nail-style-blog/swipe-right": [146,[4]],
		"/nail-style-blog/technology-in-the-salon": [147,[4]],
		"/nail-style-blog/that-is-a-wrap": [148,[4]],
		"/nail-style-blog/the-beat-goes-on": [149,[4]],
		"/nail-style-blog/the-illusion-of-perfection": [150,[4]],
		"/nail-style-blog/the-season-for-giving": [151,[4]],
		"/nail-style-blog/tips-and-tricks": [152,[4]],
		"/nail-style-blog/tips-for-toenail-health": [153,[4]],
		"/nail-style-blog/trend-alert-90s-makeup-is-back": [154,[4]],
		"/nail-style-blog/trend-alert-solid-cream-gels": [155,[4]],
		"/nail-style-blog/ways-to-stay-healthy": [156,[4]],
		"/nail-style-blog/whats-your-summer-nail-color": [157,[4]],
		"/nail-style-blog/winter-hand-rescue": [158,[4]],
		"/nail-style-blog/xpress-yourself": [159,[4]],
		"/nail-style-blog/your-essential-summer-playlist": [160,[4]],
		"/nail-style-blog/your-summer-salon-bucket-list": [161,[4]],
		"/new": [~162],
		"/our-story": [163],
		"/patents": [164],
		"/privacy": [165],
		"/products": [166],
		"/products/[...slug]": [~167],
		"/sales/order": [~170],
		"/sales/order/view/order_id/[slug]": [~171],
		"/sale": [~168],
		"/sale/[slug]": [~169],
		"/salon-locator": [172],
		"/search": [173],
		"/search/[...slug]": [~174],
		"/shipping-policy": [175],
		"/signup": [~176],
		"/soft-gel/[slug]": [~177,[6]],
		"/terms": [178],
		"/videos": [179],
		"/virtual-salon/[[slug]]": [~180],
		"/warranty-registration": [181],
		"/warranty-support": [182]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';